import * as React from "react"
import Layout from "../components/common/layout"
import Title from "../components/title"
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import TextMedia from "../components/text-media"
import Heading from "../components/common/heading"
import FeatureBenefits from "../components/feature-benefits"
import SubMenu from "../components/common/sub-menu"

const ContactUs = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const dataTextMedia = _data.find((item: any) => item.block === 'text-media')
  const featureBenefits = _data.find((item: any) => item.block === 'feature-benefits');
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  return (
    <Layout>
      <Helmet
          title='Contact us'
        />
         <SubMenu data={subMenu.data[0]}></SubMenu>
        <Title data={dataTitle.data[0]} background="bg-white" ></Title>
        <FeatureBenefits data={featureBenefits.data[0].feature_benefits} background="bg-white" noSpacing={true}></FeatureBenefits>
        <Heading 
            title='Join our team' 
            background='bg-white'  
        />
        <TextMedia data={dataTextMedia.data[0]}  background="bg-white" ></TextMedia>
    </Layout>
  )
} 

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["contact-us"]}}) {
      nodes {
        jsonName
        data {
          block
            data {
              sub_menu{
                title
                links {
                  id
                  text
                  link
                  active
                }
              }
              feature_benefits {
                id
                title
                image
                content
                links {
                  id
                  text
                  link
                }
              }
              text_media {
                title
                content
                image
                video {
                  thumbnail
                  title
                  video
                }
                links{
                  id
                  text
                  url
                }
              }
              title_data {
                title
                content
                links{
                  id
                  text
                  url
                }
              }
            }
        }
      }
    }
  }
`
export default ContactUs
